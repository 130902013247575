import { Link } from "react-router-dom"

import"./home.css"

export default function NoPageFound() {
    return (
        <div>  <body>
        <div class="box">
            <div class="text">
                <h1 >Windsurfing News Greece <br></br> is currently under construction. </h1>
                <h2>The page that you are looking for is currently unavailable or does not exist.</h2>
                <h3>Enjoy your session, and stay tuned for our return!</h3>
            </div>
        </div>

</body></div>
     
        )}
