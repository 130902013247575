
import"./home.css"

export default function Home() {
    return (
        <div>  <body>
        <div class="box">
            <div class="text">
                <h1 >Windsurfing News Greece <br></br> is currently under construction. </h1>
                <h3>Enjoy your session, and stay tuned for our return!</h3>
            </div>
        </div>

</body></div>
     
        )}
