import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/home"
import NoPageFound from "./Pages/NoPageFound";

function App() {
  return (

    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}/>
          <Route path="/Home" element={<Home />}/>
          <Route path="*" element={<NoPageFound />}/>
        </Routes>
      </BrowserRouter>
    </div>
          )}

export default App;

